import * as React from "react";
import Container from "react-bootstrap/Container";
import YoutubeEmbed from "../../../components/YoutubeEmbed/YoutubeEmbed";
import "./MediaBit.scss";

const MediaSection = () => {
  return (
    <section id="media" className="mediaBit">
      <Container className="text-center mb-3 sr-only">
        {/* This only appears in screen readers */}
        <h2 className="sectionHeading">Media</h2>
      </Container>
      <Container className=" d-flex justify-content-center">
        <div className="videoCont">
          <YoutubeEmbed embedId="nAMH6EUvI1I" />
        </div>
      </Container>
    </section>
  );
};

export default MediaSection;
